import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import navRoutes from './Routes';

////////////////////////////////////////////////////////////////////////////////////////////
// Build the main and secondary menu items from the navRoutes array (in Routes.js) used in StartPage
// 
                            // Return the menu items of 'type'
function getMenuItems(type)
{
  return(
    <div>
      {navRoutes.filter((prop, key) => { return(prop.type===type?true:false) }).map((prop, key) => {
          return (
            <NavLink to={prop.path} style={{textDecoration: "none",color: "white", fontSize: "20px","&:hover": {color: "yellow",borderBottom: "1px solid white",}}}>
                <ListItem button>
                    <ListItemIcon>
                        {prop.icon}
                    </ListItemIcon>
                    <ListItemText primary={prop.sidebarName} />
                </ListItem>
            </NavLink>
          );
        })}
    </div>
  );
}
export const mainListItems = getMenuItems("mainMenu");

export const secondaryListItems = getMenuItems("secondaryMenu");
