import * as React from 'react';
import { Route, Routes } from 'react-router';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
//import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import NotificationsIcon from '@mui/icons-material/Notifications';

import { mainListItems, secondaryListItems } from './menuItems';

//import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

import tubbies from "../images/3tubbies.jpg";
import HomePage from "./Home";
import Biography from "./Biography";
import Albums from "./Albums";
import Contact from "./Contact";
import Links from "./Links";
import Music from "./Music";
import News from "./News";
import Tourdates from "./Tourdates";

import Copyright from "./components/Copyright"

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open',})(({ theme, open }) => 
    (
      {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], 
          {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        ...(open && 
          {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], 
              {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }),
      }
    ));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => (
    {
      '& .MuiDrawer-paper': 
        {
          position: 'relative',
          whiteSpace: 'nowrap',
          width: drawerWidth,
          transition: theme.transitions.create('width', 
            {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          boxSizing: 'border-box',
          ...(!open && 
            {
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: theme.spacing(7),
              [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
              },
            }),
        },
    }),
  );

const mdTheme = createTheme({
    palette: {
      mode: 'dark',
    },
});

function DashboardContent() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }} >
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px', }}>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ marginRight: '36px',...(open && { display: 'none' }), }}>
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h4" color="inherit" noWrap sx={{ flexGrow: 1 }} fontFamily="stencil">
              TUBESTREET
            </Typography>
            <IconButton color="inherit">
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1], }}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
        <Box component="main" sx={{ backgroundColor: (theme) => theme.palette.mode === 'light'? theme.palette.grey[100]: theme.palette.grey[900], 
                                flexGrow: 1,
                                height: '100vh',
                                overflow: 'auto',
                                backgroundImage: `url(${tubbies})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                opacity: 1.0,
                              }}>
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4, }}>
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/home' element={<HomePage />} />
              <Route path='/biography' element={<Biography />} />
              <Route path='/albums' element={<Albums />} />
              <Route path='/music' element={<Music />} />
              <Route path='/links' element={<Links />} />
              <Route path='/tourdates' element={<Tourdates />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/news' element={<News />} />
            </Routes>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}