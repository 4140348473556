import * as React from 'react';


const News = () => {
    return (
      <h1>News</h1>
    );
  };

export default News;
