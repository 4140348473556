import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Biography = () => {
    return (
      <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Biography</h1>
      </Grid>
      {/* BIOGRAPHY */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <p className="style1">Tubestreet is a Belgian rockband founded in march 1993 by Luc Tondeleir, Marc Nuytkens and Peter Van Acker eager to play rock music. 
      Circumstances make the drummer to be replaced in the first year by Bart De Bruycker which completes the base of the band as 
      it exists today. As reinforcement for the backing vocals, Nadia Van Houdenhove joines the band, which adds a new dimension 
      to its vocal harmony.</p>

      <p className="style1">After a number of concerts in 1995 including the Backline Rock festival in Merelbeke (Belgium), supporting act for the Kreuners 
      in Gijzenzele (Belgium), the Windorock Trophy in Oosterzele (Belgium) and a concert in the &lsquo;Seewald&rsquo; in Stuttgart (Germany), 
      the band records their first album Downtown.
      </p>
      <p className="style1">Besides a series of local concerts such as gigs at J.J.'s Roadhouse in Knokke (B) and the &quot;Bergrock&quot; festival in Wetteren (B) 
      as supporting act for Gorki (B) and B.J. Scott (USA), Tubestreet gives a few gigs in Portsmouth (England) during 1998, as 
      the prelude for a small tour over there in 1999. In August 1999 the band performs live at the Fonnefeesten as supporting act 
      for My Velma and The Kids. Meanwhile the band records a number of new songs on a new mini-CD entitled Nowhere left 
      to go.
      </p>
      <p className="style1">In 2001 Eddy Scheire, skilled on guitars and keyboards, joins the band so the band enters their second decade as a 
      5 member line-up.      </p>
      <p>During the major part of 2007 Tubestreet records a number of older and new songs which results in the production of the Bad Day album. The new CD is a new milestone in the band's history that forms the frontline for their powerfull rock'n'roll performance.</p>
      <p>Currently the band is no longer active.</p>
        </Paper>
      </Grid>
    </Grid>
    );
  };

export default Biography;
