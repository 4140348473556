// client/src/App.js

import React from "react";
import "./App.css";
import StartPage from "./layout/StartPage.js"

function App() {
    // const [data, setData] = React.useState(null);

    // React.useEffect(() => {
    //     fetch("/api")
    //         .then((res) => res.json())
    //         .then((data) => setData(data.message));
    //   }, []);

      return (
        <StartPage />
    );
}

export default App;
