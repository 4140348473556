import React,{useState,useEffect} from 'react';
import {Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent} from "@mui/lab"
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import QueueMusicTwoToneIcon from '@mui/icons-material/QueueMusicTwoTone';
//import tubbies from "../images/3tubbies.jpg";
import Image from './components/Image'
//import tubestreet1 from "../images/tubestreet-cassette-cover.jpg";
                                    // Define the source file for the JSON data
var jsonDataFilename = './Albums.json';

function Albums() {
                                    // Define the hook to get and set the data
  const [Albums,setAlbums]=useState([]);
                                    // function to load the data
  const getAlbums=()=>{
      fetch(jsonDataFilename,
            {
                headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }
            }
        )
        .then(function(response){
          console.log(response)
          return response.json();
        })
        .then(function(myJson) {
          console.log(myJson);
          setAlbums(myJson)
        });
  }
  useEffect(()=>{
      getAlbums()
  },[])

  function showAlbumDetails() 
  {
        alert("show Album details... to be implemented");
  }

  return (
      <div>
        <h1>Albums</h1>
        <Timeline align="alternate">
        {
            Albums && Albums.length > 0 && Albums.map((album, index)=>
                  <TimelineItem key={index}>
                    <TimelineOppositeContent>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary" onClick={showAlbumDetails}>
                        <QueueMusicTwoToneIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                              <Paper variant="outlined">
                                <Typography component="h1" sx={{m:2}}>{album.title}</Typography>
                                <Image fileName={album.imageCover} width="100%" />
                              </Paper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Paper variant="outlined">
                                  <Typography variant="body1" component="h2" sx={{m:2}} >
                                    {album.releaseDate}
                                  </Typography>
                                  <Typography color="primary" sx={{m:2}}>{album.media}</Typography>
                                  <Typography color="primary" sx={{m:2}}>Recorded at: {album.recordedAt}</Typography>
                                  <Typography color="primary" sx={{m:2}}>Catalog id: {album.catalogId}</Typography>
                                  <Accordion>
                                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography>Songs</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                          {
                                              album.songs.map( (song,i)=>
                                                <Paper>
                                                  <Typography color="secondary" sx={{m:2}} title={"Lyrics by: " + song.lyricsBy + " - Composed by:" + song.composedBy}>{song.trackIndex}. {song.title}</Typography>
                                                  <Typography variant="caption">({song.composedBy})</Typography>
                                                </Paper>
                                              )
                                          }
                                      </AccordionDetails>
                                  </Accordion>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </TimelineContent>
                  </TimelineItem>
                )
        }
        </Timeline>
      </div>
    );
};

export default Albums;

