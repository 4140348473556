import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Image from './components/Image'

import homeImage from "../images/foto/Fonnefeesten---ver-zicht-me.jpg";

const HomePage = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Home</h1>
      </Grid>
      {/* Homepage */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <p className="style1">Tubestreet is a Belgian rockband founded in march 1993.</p>
          <img src={homeImage} alt="live at fonnefeesten 1999" />
          <p className="style1">At present the band is no longer active.</p>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HomePage;
