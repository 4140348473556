import * as React from 'react';
import { Typography, Paper } from "@mui/material";

 import audio1 from "../audio/Tubestreet-BadDay.mp3";
 import audio2 from "../audio/Tubestreet-Control-demo.mp3";
import audio3 from "../audio/Tubestreet-Dreamprince.mp3";
import audio4 from "../audio/Tubestreet-EveryDay.mp3";
import audio5 from "../audio/Tubestreet-FreggleCave.mp3";
import audio6 from "../audio/Tubestreet-Gimme.mp3";
import audio7 from "../audio/Tubestreet-Joyland.mp3";
import audio8 from "../audio/Tubestreet-Life.mp3";
import audio9 from "../audio/Tubestreet-Nobody.mp3";
import audio10 from "../audio/Tubestreet-Screaming.mp3";
import audio11 from "../audio/Tubestreet-Secrets.mp3";
import audio12 from "../audio/Tubestreet-Takesanapple.mp3";
import audio13 from "../audio/Tubestreet-Visions.mp3";
//import audio from "../audio/";

import ReactAudioPlayer from 'react-audio-player';

var audioList = [
  {  name: "Bad Day", ref: audio1, autoPlay: false },
  {  name: "Control", ref: audio2, autoPlay: false },
  {  name: "Dreamprince", ref: audio3, autoPlay: false },
  {  name: "Every Day", ref: audio4, autoPlay: false },
  {  name: "Freggle Cave", ref: audio5, autoPlay: false },
  {  name: "Gimme", ref: audio6, autoPlay: false },
  {  name: "Joyland", ref: audio7, autoPlay: false },
  {  name: "Life", ref: audio8, autoPlay: false },
  {  name: "Nobody", ref: audio9, autoPlay: false },
  {  name: "Screaming", ref: audio10, autoPlay: false },
  {  name: "Secrets", ref: audio11, autoPlay: false },
  {  name: "Takes an apple", ref: audio12, autoPlay: false },
  {  name: "Visions", ref: audio13, autoPlay: false }
]
function Music(){
    return (
      <div>
        <h1>Music</h1>
        {
          audioList && audioList.length > 0 && audioList.map((audioItem, index)=>
            <Paper variant="outlined">
              <Typography color="primary"  component="h1" sx={{m:2}} align="top" >{audioItem.name}</Typography>
              <Typography component="h1" sx={{m:3}} align="top" >
                <ReactAudioPlayer
                  title={audioItem.name}
                  src={audioItem.ref}
                  autoPlay={audioItem.autoPlay}
                  controls
                />
              </Typography>
            </Paper>
          )
        }
        
      </div>
    );
};

export default Music;
