import { Button, Paper, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInputText } from "./form-components/FormInputText";
import { FormInputDropdown } from "./form-components/FormInputDropdown";
import axios from "axios";

const defaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  type: "General question",
  subject: "",
  message: "",
};

const Contact = () => {
    const methods = useForm({ defaultValues: defaultValues });
    const { handleSubmit, reset, control, setValue, watch } = methods;

    const onSubmit = (data) => {
        console.log(data);
//        e.preventDefault();
//        setStatus("Verzenden...");
//        const { subject, type, firstname, lastname, phone, email, message } = e.target.elements;
        // let details = {
        //   subject: subject.value,
        //   type: type.value,
        //   message: message.value,
        //   firstname: firstname.value,
        //   lastname: lastname.value,
        //   email: email.value,
        //   phone: phone.value,
        //   };
        let messageAPI = "https://tubestreet.herokuapp.com/sendMessage";
        // Call the API on the localhost for testing when the server is running on the local machine
//        let messageAPI = "http://localhost:3000/sendMessage";
//         fetch(messageAPI, {
//                 method: "POST",
// //                mode: 'no-cors', // no-cors, *cors, same-origin
//                 headers: {
//                   "Content-Type": "application/json",
// //                  'Accept': 'application/json'
//                 },
//                 body: JSON.stringify(data),
//               }
//           )
        axios
          .post(messageAPI, data)
          .then(res => 
            {
              console.log(res.json());
            }
          )
          .then(
              (result) => {
                  alert(result.status);
                  console.log(result);
                },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                  alert(error);
                  console.log(error);
              }
          );
        // setStatus("Submit");
        //let result = res.json();
        //alert(result.status);
    };
    return (
        <Paper sx={{ margin: '36px',}}>
          <Typography variant="h2" sx={{ margin: '36px',}}>Contact</Typography>

          <FormInputText name="firstname" control={control} label="First name:" />
          <FormInputText name="lastname" control={control} label="Last name:" />
          <FormInputText name="email" control={control} label="Email:" />
          <FormInputText name="phone" control={control} label="Phone:" />
          <FormInputDropdown name="type" control={control} label="Select the subject of your inquiry :" />
          <FormInputText name="subject" control={control} label="Subject:" />
          <FormInputText name="message" control={control} label="Message:" />
          <Button onClick={handleSubmit(onSubmit)} variant={"contained"} sx={{ margin: '10px',}}>{" "} Submit{" "}</Button>

        </Paper>
      );
};

export default Contact;
