import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Links = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>Links and references</h1>
        </Grid>
        {/* Links */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <a href="http://www.ssst.be" className="style1">Simple Sound Studio</a>
          </Paper>
        </Grid>
      </Grid>    
    );
  };

export default Links;
