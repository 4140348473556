import React,{useState,useEffect} from 'react';
import {Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent} from "@mui/lab"
import { Typography } from "@mui/material";
import { Paper } from "@mui/material";
import PianoIcon from "@mui/icons-material/PianoSharp";
                                    // Define the source file for the JSON data
var jsonDataFilename = './TubestreetGigs.json';

const Tourdates = () => {
                                    // Define the hook to get and set the data
    const [tourdates,setTourdates]=useState([]);
                                    // function to load the data
    const getTourDates=()=>{
      fetch(jsonDataFilename,
            {
                headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }
            }
        )
        .then(function(response){
          console.log(response)
          return response.json();
        })
        .then(function(myJson) {
          console.log(myJson);
          setTourdates(myJson)
        });
    }
    useEffect(()=>{
      getTourDates()
    },[])

    function addNewPeriod() 
    {
        alert("Add new date?");
    }

    return (
            <div>
            <h1>Tourdates</h1>
            <Timeline align="alternate">
            {
                tourdates && tourdates.length > 0 && tourdates.map((gig)=>
                      <TimelineItem>
                        <TimelineOppositeContent>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color="primary" onClick={addNewPeriod}>
                            <PianoIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Paper elevation={300}>
                            <Typography variant="h5" color="success" sx={{m:2}}>
                              {gig.date}
                            </Typography>
                          </Paper>
                          <Paper elevation={3} variant="outlined">
                            <Typography variant="body1" component="h2" sx={{m:2}} >
                              {gig.venue}
                            </Typography>
                            <Typography color="primary" sx={{m:2}}>{gig.location}</Typography>
                          </Paper>
                        </TimelineContent>
                      </TimelineItem>
                    )
            }
            </Timeline>
            </div>
        );
  };

  export default Tourdates;