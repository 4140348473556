import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
//import PeopleIcon from '@mui/icons-material/People';
import AlbumIcon from '@mui/icons-material/Album';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LinkIcon from '@mui/icons-material/Link';
//import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
//import ContactMail from '@mui/icons-material/ContactMail';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AnnouncementIcon from '@mui/icons-material/Announcement';

//////////////////////////////////////////////////////////////////////////////////////////////////
// Define the possible menu items to point to a route specified under <Routes> in the Startpage
// items of type 'mainMenu' will apear as the first list
// items of type 'secondaryMenu' will apear as the second list

const navRoutes = [
  {
    type: "mainMenu",
    path: '/',
    sidebarName: 'Home',
    icon: <HomeIcon />
  },
  {
    type: "mainMenu",
    path: '/Biography',
    sidebarName: 'Biography',
    icon: <FingerprintIcon />
  },
  {
    type: "mainMenu",
    path: '/Albums',
    sidebarName: 'Albums',
    icon: <AlbumIcon />
  },
  {
    type: "mainMenu",
    path: '/Music',
    sidebarName: 'Music',
    icon: <LibraryMusicIcon />
  },
  {
    type: "mainMenu",
    path: '/Links',
    sidebarName: 'Links',
    icon: <LinkIcon />
  },
  {
    type: "secondaryMenu",
    path: '/Tourdates',
    sidebarName: 'Tourdates',
    icon: <DateRangeIcon />
  },
  {
    type: "secondaryMenu",
    path: '/Contact',
    sidebarName: 'Contact',
    icon: <ForwardToInboxIcon />
  },
  {
    type: "secondaryMenu",
    path: '/News',
    sidebarName: 'News',
    icon: <AnnouncementIcon />
  },
];


export default navRoutes;